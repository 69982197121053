$(document).ready(function(){

    const scroller = document.querySelector("#header");
    window.addEventListener("scroll", (event) => {

        let stickyTop  = document.documentElement.scrollTop
        if (stickyTop > 20 ) {
            scroller.classList.add('sticky');
            $('#scroller').css('opacity','0');
            $('#scroller').css('transform','translateY(-200%)');
        } else {
            if( scroller.classList.contains('sticky') ){
                scroller.classList.remove('sticky');
            }
            $('#scroller').css('opacity','1');
            $('#scroller').css('transform','translateY(0)');
        }
    });

    // $('#response_modal').modal('show');

    $('#menu_opener').click( function (){
        $('#side_menu').slideToggle();
    });

    $('.accordion-button').click( function (e){
        $('.accordion-button').not( $(e.currentTarget) ).each(function(){
            if( $(this).parent().find('.sub_list_menu').hasClass('show') ){
                $(this).parent().find('.sub_list_menu').removeClass('show');
            }
            if( !$(this).hasClass('collapsed') ){
                $(this).addClass('collapsed');
            }
        });
    });

    $('#side_menu .sub_list_menu').each( function (){
        let old_id = $(this).attr('id');
        let new_id = old_id + '_mobile';
        $(this).attr("id",new_id);
    });

    $('#side_menu .text_sub_menu').each( function (){
        let old_id = $(this).attr('aria-controls');
        let new_id_target = '#' + old_id + '_mobile';
        let new_id_controls = old_id + '_mobile';
        $(this).attr("data-bs-target",new_id_target);
        $(this).attr("aria-controls",new_id_controls);
    });

    $('#side_menu .language_menu button').each( function (){
        let old_id = $(this).attr('id');
        let new_id = old_id + '_mobile';
        $(this).attr("id",new_id);
    });

    $('.login_popup_opener').click( function (){
        $(this).toggleClass('clicked');
        $(this).parent().find('.login_popup').slideToggle();
    });

    $('.client_project').click( function (e){
        $(this).find('.project_title').toggleClass('toggled');
        $(this).find('.project_content').slideToggle();
        console.log($(e.target));
    });

    let is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    let is_Edge = navigator.userAgent.indexOf("Edge") > -1;
    let is_chrome = !!window.chrome && !is_opera && !is_Edge;
    let is_explorer= typeof document !== 'undefined' && !!document.documentMode && !is_Edge;
    let is_firefox = typeof window.InstallTrigger !== 'undefined';
    let is_safari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 &&  navigator.userAgent.indexOf('Android') == -1;

    if (is_safari || is_opera || is_Edge || is_explorer) {
        $('#lines g').addClass('no_anim');
    }

    $("h1:has(p)").find("p").contents().unwrap();
    $("h2:has(p)").find("p").contents().unwrap();
    $("h3:has(p)").find("p").contents().unwrap();
});