$(document).ready(function(){
    $("#projects_select").change(function () {
        let selection = $("#projects_select option:selected").val();
        selectElements(selection);
    });

    function selectElements(selectValue){
        $('#project_list').find('.single_project').each( function (){
            if ( !$(this).hasClass(selectValue)){
                $(this).fadeOut();
            } else {
                $(this).fadeIn();
            }
            if ( selectValue == "all"){
                $(this).fadeIn();
            }
        });
    }
});