$(document).ready(function(){
    let theButton = $("#close_modal");
    theButton.click( function (){
        location.reload();
    });
    let thePopup = $("#response_modal");
    let thePopupSubmit = $("#submit_password_recover");
    let thePopupButton = $("#close_popup");
    thePopupSubmit.click( function (e){
        // e.preventDefault();
        thePopup.fadeIn();
    });
    thePopupButton.click( function (){
        thePopup.fadeOut();
    });
});